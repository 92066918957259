<template>
    <b-container fluid class="b-container-no-padding">
        <CommonTabsComponent
            :tabList="tabList"
            @tabChanged="tabChanged"
        ></CommonTabsComponent>
        <component v-bind:is="componentName" />
    </b-container>
</template>
<script>
export default {
    name: 'DreamsWrapper',
    components: {
        CommonTabsComponent: () =>
            import('../CommonComponents/CommonTabsComponent.vue'),
        RiskModel: () => import('./RiskModel.vue'),
        HydraulicsModel: () => import('./HydraulicsModel.vue'),
        CipOptimization: () => import('./CipOptimization.vue'),
    },
    data() {
        return {
            selectedTabName: 'Risk Modeling',
            tabList: [{ name: 'Risk Modeling', component: 'risk-model' }],
        };
    },
    methods: {
        tabChanged(newTab, prevTab) {
            this.selectedTabName = newTab.name;
        },
        populateTabList() {
            this.tabList = [
                { name: 'Risk Modeling', component: 'risk-model' },
                { name: 'Hydraulics', component: 'hydraulics-model' },
                {
                    name: 'CIP Optimization',
                    component: 'cip-optimization',
                },
            ];
            return this.tabList;
        },
    },
    activated() {
        this.populateTabList();
    },
    mounted() {
        this.populateTabList();
    },
    computed: {
        componentName() {
            return this.tabList.find((tab) => tab.name === this.selectedTabName)
                .component;
        },
    },

    watch: {},
};
</script>
<style lang="scss" scoped>
.b-container-no-padding {
    padding: 0px;
}
</style>
